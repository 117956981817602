<template>
    <component :is="as" @click.stop="doCancel" :class="className" :title="hasChanges ? $t('Cancel pending changes') : ''" :disabled="isDisabled" :style="{ 'cursor': isDisabled ? 'inherit' : 'pointer' }">
        <slot :hasChanges="hasChanges" :isSaving="isSaving" :isDisabled="isDisabled">
            <template v-if="as !== 'i' && as !== 'img'">
                {{ $t('Cancel') }}
            </template>            
        </slot>
    </component>
</template>

<script setup>
/**
* Renders cancel button with spinner while running.
 Need to provide dataObject or row, if data object provided will cancel change for current row.
 Add default slot with text or/and icon to override default.
*@definition
*/
import { useAttrs, computed } from 'vue';
import { getDataObjectById } from 'o365-dataobject';

const emits = defineEmits(['canceled']);

const props = defineProps({
    dataObject: {
        type: Object,
        default: undefined
    },   
    row: {
        type: Object,
        default: undefined
    },
    as: {
        type: String,
        default: "button"
    },
    enabledWithoutChanges: {
        type: Boolean
    },
    disabledClasses: {
        type: String,
        required: false,
        default: "",
    },
});

const attrs = useAttrs();

const _dataObject = computed(() => {
    return props.dataObject ?? getDataObjectById(props.row.dataObjectId, props.row.appId);
});

const _row = computed(() => {
    return props.row ?? _dataObject.value.current;
});

const isSaving = computed(() => {
    return _row.value.isSaving ?? false;
});

const hasChanges = computed(() => {
    return (_row.value?.hasChanges || _row.value?.isNewRecord) ?? false;
});

const isDisabled = computed(() => {
    if (!isSaving.value && props.enabledWithoutChanges) {
        return false;
    }

    return isSaving.value || !hasChanges.value;
});

const className = computed(() => {
    return (attrs.class ? '' : 'btn btn-sm btn-link') + " " + (isDisabled.value ? props.disabledClasses : "");
});

function doCancel() {
    if (_row.value.cancelChanges) {
        //_row.value.cancelChanges();
        _dataObject.value.cancelChanges(_row.value.index);
    } else {
        _dataObject.value.cancelChanges(_row.value.index);
    }

    emits('canceled', _row.value);
}

</script>

